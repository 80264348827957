<template>
    <Layout>
        <div class="vertical-center d-flex">
            <div class="mx-auto">
                <img src="@/assets/images/page-not-found.svg" class="d-block" alt="">
                <span class="d-flex mt-4">
                    <router-link class="btn btn-primary mx-auto" :to="{name: 'Home'}">BACK TO HOME</router-link>
                </span>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../layouts/main";
export default {
    components: {
    Layout
  },
}
</script>

<style scoped>
.vertical-center {
  min-height: 70vh; /* These two lines are counted as one :-)       */
  align-items: center;
}
</style>